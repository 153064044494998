<template>
  <div class="bg-white py-24 sm:py-32 bg-neutral-100">
    <div class="mx-auto max-w-7xl px-6 lg:px-24">
      <div class="mx-auto max-w-2xl lg:mx-0">
        <h2 class="text-2xl font-bold tracking-tight text-gray-900">
          Talking Points
        </h2>
        <p class="mt-6 text-lg leading-8 text-gray-800 font-semibold">
          Our customer service center will continue to always be available to
          answer any questions or concerns that arise from any participants.
        </p>
      </div>
      <dl
        class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 text-base leading-7 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-2"
      >
        <div v-for="feature in features" :key="feature.name" class="list-disc">
          <!-- <dt class="font-semibold text-gray-900">{{ feature.name }}</dt> -->
          <dd class="mt-1 text-gray-800" v-html="feature.description"></dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      features: [
        {
          name: "Push to deploy.",
          description:
            "<span>This change will bring participants a modern platform and greater conveniences, like instant claims processing, real-time claims reimbursements, and cards that have 'tap to pay' and digital wallet capabilities. With this change, RMR will be able to serve clients and participants even faster.</span>"
        },
        {
          name: "SSL certificates.",
          description:
            "<span>RMR will take care of moving account balances, data transfers, testing, and communications. Employers and participants will have to complete a few minor actions, but we will make it as simple and streamlined as possible.</span>"
        },
        {
          name: "Simple queues.",
          description:
            "<span>While not required, it is strongly recommended that participants move their HSA balances to the new system (under UMB as the custodian) to avoid having two HSAs and paying admin fees for the former, non-supported HSA.</span>"
        },
        {
          name: "Advanced security.",
          description:
            "<span>On the new system, employers and their employees will use a new and upgraded employer dashboard, new payment cards, and new portal and mobile app for participants.</span>"
        },
        {
          name: "Powerful API.",
          description:
            "<span>Your trusted RMR team is not changing. We will still deliver the same service levels, adhere to the same pricing and contract agreements, and honor all client commitments. There will be no impact on our ability to serve our clients and employees during this transition."
        }
      ]
    };
  }
};
</script>
